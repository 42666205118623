<template>
  <b-card
    v-if="data"
    class="card-transaction"
    no-body
  >
    <div v-if="!isLoading">
      <b-card-header>
        <b-card-title>Paises de procedencia de turistas</b-card-title>
      <!--  <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item href="#">
          Last 28 Days
        </b-dropdown-item>
        <b-dropdown-item href="#">
          Last Month
        </b-dropdown-item>
        <b-dropdown-item href="#">
          Last Year
        </b-dropdown-item>
      </b-dropdown> -->
      </b-card-header>
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scroll-area"
        tag="li"
      >
        <b-card-body>

          <div
            v-for="paisProc in data"
            :key="paisProc.id"
            class="transaction-item"
          >
            <b-media no-body>
              <b-media-aside>
                <b-avatar
                  rounded
                  size="34"
                  variant="transparent"
                >
                  <b-img
                    fluid
                    :src="require(`@/assets/flags/${getAssetFlag(paisProc.id)}.svg`)"
                    class="pricing-trial-img"

                    alt="svg img"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6
                  class="transaction-title"
                  style="margin-top: 9px;"
                >
                  {{ paisProc.id }}
                </h6>
                <!--  <small>{{ paisProc.types }}</small> -->
              </b-media-body>
            </b-media>
            <div
              class="font-weight-bolder"
            >
              <!--   :class="transaction.deduction ? 'text-danger':'text-success'" -->
              {{ paisProc.value }}
            </div>
          </div>

        </b-card-body>
      </vue-perfect-scrollbar>
    </div>
    <div
      v-else
      class="align-items-center justify-content-center  d-flex"
      :style="{height: !isLoading? '': '300px'}"
    >  <b-spinner
      style="width: 3rem; height: 3rem;"
      class="mr-1"
      label="Large Spinner"
      variant="primary"
    /></div>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BSpinner, BImg, /* ,  BDropdown, BDropdownItem, */
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    VuePerfectScrollbar,
    BAvatar,
    BSpinner,
    BImg,
    /*   */

    /*  BDropdown,
    BDropdownItem, */
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: true,
    },

  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      flags: [],
    }
  },
  computed: {
    getAssetFlag() {
      return code => {
        let codeFlag = code.toLowerCase()
        try {
          codeFlag = this.convertir(codeFlag)
          /* replace space for _ */
          codeFlag = codeFlag.replace(/ /g, '_')
          if (codeFlag === '') {
            return 'peru'
          }
          // eslint-disable-next-line import/no-dynamic-require
          if (require(`@/assets/flags/${codeFlag}.svg`)) {
            return codeFlag
          }
          return 'peru'
        } catch (error) {
          return 'peru'
        }
      }
    },
    /* convertir ñ a n quitar tildes */
    convertir() {
      return text => {
        const textConvert = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        return textConvert
      }
    },
  },
  methods: {

  },
}
</script>
<!-- style sasss -->
<style lang="scss" scoped>
.scroll-area {
  /* height: 555px; */
  height: 230px;
  /*
  overflow-y: auto;
  overflow-x: hidden; */
}
</style>
