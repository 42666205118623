<template>
  <section id="dashboard-analytics">
    <b-row>
      <b-col
        lg="12"
        md="12"
      >
        <h3 class="text-center font-weight-bold">
          Llegada de Turistas Internacionales :  {{ formatNumberWithCommas(turistasTotal) }}
        </h3>
      </b-col>
      <b-col
        lg="12"
        md="12"
      >
        <GmapMap
          :center="{lat:10, lng:10}"
          :zoom="2"
          map-type-id="terrain"
          style="width: 100%; height: 300px"
          :options="{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: true,
            disableDefaultUi: false
          }"
        >
          <GmapCircle
            v-for="(pin, index) in markers"
            :key="index"
            :center="pin.center"
            :radius="pin.cantidad"
            :visible="true"
            :options="{fillColor:'blue',fillOpacity:0.3,strokeWeight: 1,}"
            :clickable="true"
            @click="mostrarMap"
          >

            />
          </gmapcircle>
          <GmapMarker
            v-for="(pin, index) in markers"
            :key="pin.id"
            :position="pin.center"
            :clickable="true"
            :icon="{ url: require(`@/assets/transparent.svg`), size: {width: 20, height: 10, f: 'px', b: 'px',},
            }"
            @mouseout="showByIndex = null"
            @mouseover="showByIndex = index;"
          >
            <!-- scaledSize: {width: 30, height: 45, f: 'px', b: 'px',} -->
            <gmap-info-window
              :opened="showByIndex === index"
            >
              <span>País: {{ pin.id }}</span> <br>
              <span>Llegada de turistas internacionales {{ pin.cantidadVal }}</span>
            </gmap-info-window>
          </GmapMarker>
        </GmapMap>

      </b-col>
      <b-col
        lg="4"
        md="4"
      >
        <div class="apply-job-package bg-light-primary rounded">
          <div class="m-1">
            <sup class="text-body">
              <small>Turistas Internacionales:</small>
            </sup>
            <h2 class="d-inline mr-25">
              {{ formatNumberWithCommas(turistasTotal) }}
            </h2>
            <!--  <sub class="text-body"><small>/ month</small></sub> -->
          </div>

        </div>
        <MinceturTuristasProcedencia
          :data="listAnualTuristasInternacionalByAnio"
          :anios="selectedAnio"
          :is-loading="loadAnualTuristasInternacionalByAnio"
          @listen="changeAnio"
        />

      </b-col>
      <b-col
        lg="8"
        md="8"
        class="mt-1"
      >
        <MinceturTuristasInternacional
          :data="listTuristasInternacionales.valore"
          :anios="listTuristasInternacionales.labels"
          :is-loading="loadAnualTuristasInternacional"
        />
      <!--   <MinceturTuristasInternacionalMes
          :data="listMesTuristasInternacional.valore"
          :anios="listMesTuristasInternacional.labels"
          :is-loading="loadMesTuristasInternacionalByAnio"
          @listen="changeAnio"
        /> -->
      </b-col>
      <!--  <b-col
        lg="6"
        md="6"
      >
        <Demo
          :data="listDemo.valore"
          :anios="listDemo.labels"
          :is-loading="loadAnualTuristasInternacional"
          @listen="changeAnio"
        />
      </b-col> -->
      <b-col
        lg="12"
        md="12"
      >
        <MinceturTuristasInternacionalMes
          :data="listMesTuristasInternacional.valore"
          :anios="listMesTuristasInternacional.labels"
          :is-loading="loadMesTuristasInternacionalByAnio"
          @listen="changeListAnio"
        />
      </b-col>
      <!--    <Demo
        :data="listDemo.valore"
        :anios="listDemo.labels"
        :is-loading="loadAnualTuristasInternacional"
        @listen="changeAnio"
      /> -->
    </b-row>

    <b-modal
      id="map"
      ref="map"
      title="Banderas"
      ok-title="Guardar"
      cancel-variant="outline-secondary"
      size="lg"
      no-close-on-backdrop
      scrollable
    >
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="mr-1"
            @click="hide"
          >
            Cancelar
          </b-button>
          <BButton
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          >
            Guardar
          </BButton>
        <!--    <BButton
          v-if="!isLoading"
            v-else
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            disabled
          >
            <feather-icon
              icon="LoaderIcon"
              class="mr-50"
            />
            <span class="align-middle">Cargando</span>
          </BButton> -->
        </div>
      </template>

    </b-modal>
  </section>
</template>

<script>
import { VBTooltip, BRow, BCol, BModal, BButton } from 'bootstrap-vue'
import paises from '@/assets/countries.json'
import { extractAnio } from '@/helpers/strings'
import formatNumberWithCommas from '@/helpers/number'
import MinceturTuristasInternacional from './MinceturTuristasInternacional.vue'
import MinceturTuristasProcedencia from './MinceturTuristasProcedencia.vue'
/* import Demo from './Demo.vue' */
import MinceturTuristasInternacionalMes from './MinceturTuristasInternacionalMes.vue'

export default { directives: {
  'b-tooltip': VBTooltip,
},
components: {
  BRow, BCol, MinceturTuristasInternacional, MinceturTuristasProcedencia, /*  Demo, */ MinceturTuristasInternacionalMes, BModal, BButton,
},
props: {
  listening: {
    type: Boolean,
    default: true,
  },
},
data() {
  return {
    listTuristasInternacionales: {
      labels: [],
      valore: [],
    },
    loadAnualTuristasInternacional: true,
    loadAnualTuristasInternacionalByAnio: true,
    loadMesTuristasInternacionalByAnio: true,
    listAnualTuristasInternacionalByAnio: [],
    listDemo: {
      labels: [],
      valore: [],
    },
    listMesTuristasInternacional: {
      labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic'],
      valore: [],
    },
    selectedAnio: 2024,
    selectedAnios: [2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024],
    listPaises: paises.ref_country_codes,
    markers: [],
    centerMap: { lat: 10, lng: 10 },
    showByIndex: null,
    turistasTotal: '',
  }
},
computed: {
  getAssetFlag() {
    return code => {
      let codeFlag = code.toLowerCase()
      try {
        codeFlag = this.convertir(codeFlag)
        /* replace space for _ */
        codeFlag = codeFlag.replace(/ /g, '_')
        if (codeFlag === '') {
          return 'peru'
        }
        // eslint-disable-next-line import/no-dynamic-require
        if (require(`@/assets/flags/${codeFlag}.svg`)) {
          return codeFlag
        }
        return 'peru'
      } catch (error) {
        return 'peru'
      }
    }
  },
  convertir() {
    return text => {
      const textConvert = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      return textConvert
    }
  },

},
created() {
  this.getAnualTuristasInternacional()
  this.getAnualTuristasInternacionalByAnio()
  this.getMesTuristasInternacional()
},
methods: {
  clickMe() {
    this.listening = !this.listening
  },
  async  getAnualTuristasInternacionalByAnio() {
    /* store dispatch  */
    this.markers = []
    this.turistasTotal = 0
    this.loadAnualTuristasInternacionalByAnio = true
    await this.$store.dispatch('tablero/ANUAL_TURISTAS_INTERNACIONAL', { tipo: 'xanio', anio: this.selectedAnio }).then(response => {
      this.listAnualTuristasInternacionalByAnio = response
      this.listAnualTuristasInternacionalByAnio.sort((a, b) => {
        const d = b.cantidad - a.cantidad
        return d
      })
      // this.centerMap = { lat: this.listAnualTuristasInternacionalByAnio[0], lng: 10 }
      this.listAnualTuristasInternacionalByAnio.forEach(item => {
        if (item.pais === 'Total') {
          this.turistasTotal = item.cantidad
        } else {
          this.listDemo.labels.push(item.pais)
          this.listDemo.valore.push(item.cantidad)
          /* eliminar tildes, ñ  */
          const nombrePais = item.pais.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          const found = this.listPaises.find(pais => pais.country === nombrePais)

          if (found) {
            this.markers.push({ center: { lat: found.latitude, lng: found.longitude },
              cantidadVal: item.cantidad,
              cantidad: Math.sqrt(item.cantidad) * 2000,
              id: nombrePais })
          } else {
            // console.log(nombrePais)
          }
        }
      })
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      this.loadAnualTuristasInternacionalByAnio = false
    })
  },
  async getAnualTuristasInternacional() {
    this.loadAnualTuristasInternacional = true
    this.listTuristasInternacionales.labels = []
    this.listTuristasInternacionales.valore = []

    await this.$store.dispatch('tablero/ANUAL_TURISTAS_INTERNACIONAL', { tipo: 'all', anio: '' }).then(response => {
      response.forEach(month => {
        this.listTuristasInternacionales.labels.push(extractAnio(month.anio))
        this.listTuristasInternacionales.valore.push(month.cantidad)
      })
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      this.loadAnualTuristasInternacional = false
    })
  },

  changeAnio(anio) {
    this.selectedAnio = anio
    this.listAnualTuristasInternacionalByAnio = []
    this.getAnualTuristasInternacionalByAnio()
  },
  changeListAnio(listAnios) {
    this.selectedAnios = listAnios
    this.getMesTuristasInternacional()
  },

  async getMesTuristasInternacional() {
    this.loadMesTuristasInternacionalByAnio = true
    this.listMesTuristasInternacional.labels = []
    this.listMesTuristasInternacional.valore = []

    await this.$store.dispatch('tablero/MES_TURISTAS_INTERNACIONAL', { anios: this.selectedAnios.join(',') }).then(response => {
      this.selectedAnios.forEach(anio => {
        const listValores = []
        this.listMesTuristasInternacional.labels.push(anio)
        response[anio].forEach(valor => {
          listValores.push(valor.cantidad)
        })
        this.listMesTuristasInternacional.valore.push({ name: anio, data: listValores })
      })
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      this.loadMesTuristasInternacionalByAnio = false
    })
  },
  mostrarMap() {
    this.$bvModal.show('map')
  },
  formatNumberWithCommas,
},
}
</script>

<style lang="scss">

</style>
