<template>
  <b-card
    v-if="data"
    no-body
    class="card-statistics"
  >
    <div v-if="!isLoading">
      <b-card-header>
        <b-card-title>Cantidad de visitas de turistas en</b-card-title>
        <b-card-text class="font-small-2 mr-25 mb-0">
          Actualizado hace 1 día
        </b-card-text>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="item in data"
            :key="item.id"
            xl="3"
            sm="6"
          >
            <b-media no-body>
              <b-media-aside

                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="info"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-people-group"
                    size="2x"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.id }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.value }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </div>
    <div
      v-else
      class="align-items-center justify-content-center  d-flex"
      :style="{height: !isLoading? '': '200px'}"
    >  <b-spinner
      style="width: 3rem; height: 3rem;"
      class="mr-1"
      label="Large Spinner"
      variant="primary"
    /></div>

  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody, BCardText, BSpinner,
  BRow, BCol,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    BSpinner,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
    BCol,
    BRow,

  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    meses: {
      type: Array,
      default: () => ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    },
    isLoading: {
      type: Boolean,
      default: true,
    },

  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      anioSelect: 2024,
      anio: [
        2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024,
      ],
      chartOptions3: {

        chart: {
          sparkline: { enabled: true },
          toolbar: { show: false },
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        states: {
          hover: {
            filter: 'none',
          },
        },
        colors: [
          '#ebf0f7',
          '#ebf0f7',
          $themeColors.primary,
          '#ebf0f7',
          '#ebf0f7',
          '#ebf0f7',
        ],
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
            endingShape: 'rounded',
          },
        },
        tooltip: {
          x: { show: false },
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          position: 'top',
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },

        },
      },
      chartOptions: {
        chart: {
          toolbar: { show: false },
          /* sparkline: { enabled: true }, */
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        states: {
          hover: {
            filter: 'none',
          },
        },
        colors: [
          $themeColors.primary,
        ],
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top',
            },
            endingShape: 'rounded',
            columnWidth: '45%',
            /* distributed: true, */
          },
        },
        /* cabecera eliminar */
        dataLabels: {
          enabled: true,
          formatter(val) {
            return `${val}`
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758'],
          },
        },

        xaxis: {
          categories: this.meses,
          position: 'top',
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        tooltip: {
          x: { show: true },
        },
      },
      loadCantidadTuristasMes: false,
    }
  },
  computed: {
    series() {
      return [{
        name: 'Turistas',
        data: this.data,
      }]
    },
  },
  watch: {
    meses: {
      immediate: true,
      handler(val) {
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            categories: val,
          },
        }
      },
    },
  },
  created() {
  },
  methods: {
    changeData(anio) {
      this.anioSelect = anio
      this.$emit('listen', anio)
    } },
}
</script>

<style scoped>
</style>
