<template>
  <b-card
    v-if="data"
    class="card-transaction "
    no-body
  >
    <div v-if="!isLoading">
      <b-card-header>
        <b-card-title>Visitas de turistas por meses </b-card-title>
        <b-col
          cols="12"
          md="3"
          sm="12"
        >

          <!--  <b-form-group
         label="Año"
          label-for="año"
        >  -->
          <v-select
            v-model="anioSelect"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="anio"
            @input="changeData"
          />
        <!--  </b-form-group> -->
        </b-col>
      <!--  <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item href="#">
          Last 28 Days
        </b-dropdown-item>
        <b-dropdown-item href="#">
          Last Month
        </b-dropdown-item>
        <b-dropdown-item href="#">
          Last Year
        </b-dropdown-item>
      </b-dropdown> -->
      <!-- <button @click="changeData" /> -->
      </b-card-header>

      <b-card-body>
        <vue-apex-charts
          ref="chart"
          type="bar"
          height="500"
          :options="chartOptions"
          :series="series"
        />

      </b-card-body>
    </div>

    <div
      v-else
      class="align-items-center justify-content-center  d-flex"
      :style="{height: !isLoading? '': '300px'}"
    >  <b-spinner
      style="width: 3rem; height: 3rem;"
      class="mr-1"
      label="Large Spinner"
      variant="primary"
    />
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BCol, BSpinner,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    /*  BFormGroup, */
    VueApexCharts,
    BSpinner,
    BCol,
    /*  BAvatar,
    BDropdown,
    BDropdownItem, */
    vSelect,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    meses: {
      type: Array,
      default: () => ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    },
    isLoading: {
      type: Boolean,
      default: true,
    },

  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      anioSelect: 2024,
      anio: [
        2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024,
      ],
      chartOptions3: {

        chart: {
          sparkline: { enabled: true },
          toolbar: { show: false },
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        states: {
          hover: {
            filter: 'none',
          },
        },
        colors: [
          '#ebf0f7',
          '#ebf0f7',
          $themeColors.primary,
          '#ebf0f7',
          '#ebf0f7',
          '#ebf0f7',
        ],
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
            endingShape: 'rounded',
          },
        },
        tooltip: {
          x: { show: false },
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          position: 'top',
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },

        },
      },
      chartOptions: {
        chart: {
          toolbar: { show: false },
          /* sparkline: { enabled: true }, */
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        states: {
          hover: {
            filter: 'none',
          },
        },
        colors: [
          '#2EA5BA',
          '#FF92FF',
          '#828BE8',
          '#4AA049',
          '#D57477',
          '#D352D5',
          '#C973FD',
          '#6E78CE',
          '#C973FD',
          '#D352D5',
          '#D57477',
          '#4AA049',
        ],
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top',
            },
            endingShape: 'rounded',
            columnWidth: '45%',
            distributed: true,
          },
        },
        /* cabecera eliminar */
        dataLabels: {
          enabled: true,
          formatter(val) {
            return `${val}`
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758'],
          },
        },

        xaxis: {
          categories: this.meses,
          position: 'top',
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        tooltip: {
          x: { show: true },
        },
        legend: {
          show: false,
        },
      },
      loadCantidadTuristasMes: false,
    }
  },
  computed: {
    series() {
      return [{
        name: 'Turistas',
        data: this.data,
      }]
    },
    /*  chartOptions() {
      return {
        chart: {
          toolbar: { show: false },
          sparkline: { enabled: true },
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        states: {
          hover: {
            filter: 'none',
          },
        },
        colors: [
          $themeColors.primary,
        ],
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top',
            },
            endingShape: 'rounded',
            columnWidth: '45%',
            distributed: true,
          },
        },
        dataLabels: {
          enabled: true,
          formatter(val) {
            return `${val}`
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758'],
          },
        },

        xaxis: {
          categories: this.meses,
          position: 'top',
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        tooltip: {
          x: { show: true },
        },
      }
    }, */
  },
  watch: {
    meses: {
      immediate: true,
      handler(val) {
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            categories: val,
          },
        }
      },
    },
  },
  created() {
  },
  methods: {
    changeData(anio) {
      this.anioSelect = anio
      this.$emit('listen', anio)
    } },
}
</script>

<style scoped>
  .loading {
    /* position: absolute; */
   /*  top: 0;
    left: 0; */
    width: 100%;
    height: 500px;
   /*  background: rgba(255, 255, 255, 0.5); */
   /*  z-index: 999; */

    justify-content: center;
    align-items: center;
  }
</style>
