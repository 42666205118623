<template>
  <b-card
    v-if="data"
    no-body
    :style="{height: !isLoading? '': '300px'}"
  >
    <div v-if="!isLoading">
      <b-card-header>
        <h4 class="mb-0">
          Total de turistas por años
        </h4>
      <!--  <b-card-text class="font-medium-5 mb-0">
        <feather-icon
          icon="HelpCircleIcon"
          size="21"
          class="text-muted cursor-pointer"
        />
      </b-card-text> -->
      </b-card-header>

      <!-- apex chart -->
      <vue-apex-charts
        type="donut"
        height="185"
        class="my-2"
        :options="chartOptions"
        :series="data"
      />
    </div>
    <div
      v-else
      class="align-items-center justify-content-center  d-flex"
      :style="{height: !isLoading? '': '300px'}"
    >  <b-spinner
      style="width: 3rem; height: 3rem;"
      class="mr-1"
      label="Large Spinner"
      variant="primary"
    /></div>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, /* BCardText, */BSpinner,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
/* import { $themeColors } from '@themeConfig' */

export default {
  components: {
    BCard,
    BCardHeader,
    VueApexCharts,
    BSpinner,
    /* BCardText, */
  /*   vSelect, */
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    anios: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: true,
    },

  },
  data() {
    return {
      chartOptions: {
        chart: {
          toolbar: { show: false },
          /* sparkline: { enabled: true }, */
        },
        legend: {
          formatter(val, opts) {
            return `${val} - ${opts.w.globals.series[opts.seriesIndex]} Turistas`
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: 'gradient',
        },
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        }],

      },
      loadTuristasAnio: false,
    }
  },
  computed: {
    series() {
      return [{
        /* name: 'Turistas', */
        data: this.data,
      }]
    },
  },
  watch: {
    anios: {
      immediate: true,
      handler(val) {
        this.chartOptions = {
          ...this.chartOptions,
          labels: val,
        }
      },
    },
  },
  created() {
  },
  methods: {
  },
}
</script>
